<template>
  <van-skeleton v-if="loading" :row="6" />
  <div v-else class="page">
    <div v-if="type==='tag'" class="head">
      <div class="title">
        <p>
          <span class="b">tag：</span>
          <span>{{tag_info.name}}</span>
        </p>
      </div>
    </div>
    <div v-else class="head">
      <div class="title">
        <p>
          <span>精选名字</span>
        </p>
      </div>
    </div>
    <van-skeleton v-if="loading2" :row="5" />
    <div v-else>
      <router-link class="one_name2"  v-for="( item,index) in list" :key="index" :to="{name : 'name_detail', query : {name : item.word}}" >
        <div :class="'name word_'+ item.color">{{item.word}}</div>
        <div class="desc">
          <p>{{item.desc}}</p>
        </div>
      </router-link>
      <p v-if="page===-1  && !loading2" class="tips">-- End --</p>
      <button v-if="page !==-1 && !loading2" class="in_body black" @click="get_lists">查看更多</button>
      <p v-if="can_see_more" class="warn_tips"  data-value="bind_tips" @click="xml_go_to">使用微信小程序，体验更佳。查看如何绑定</p>
    </div>
    <ul id="last_right_c" class="last_right">
      <li  @click="xml_go_to_only" data-value="index"  class="home"><img src="@/assets/img/home.png" /></li>
    </ul>
  </div>
</template>

<script>
import axios from 'axios'
import Utils from '@/utils/utils'

export default {
  name: 'name_list',
  data () {
    return {
      type: '',
      tag_info: {},
      search_info: {},
      loading: true,
      loading2: false,
      list: [],
      page: 1
    }
  },
  created () {
    this.type = this.$route.query.type
    this.search_info = { value: this.$route.query.value, type: this.$route.query.type, xing: this.$route.query.xing, page: 1 }
    if (this.type === 'tag') {
      this.tag_name_index()
    } else {
      this.get_lists()
    }
  },

  methods: {
    tag_name_index () {
      axios.post('/tag_name_index/', { search_info: this.search_info })
        .then(res => {
          if (res.data.code !== 200) {
            Utils.alert({ content: res.data.msg })
            return
          }
          this.loading = false
          this.tag_info = res.data.data.tag_info
          this.get_lists()
        })
    },
    get_lists () {
      if (this.loading2 || this.page === -1) {
        return
      }
      this.loading2 = true
      if (this.type === 'tag') {
        const data = { xing: this.search_info.xing, page: this.page, search_info: this.search_info }
        axios.post('/tag_name_list/', data)
          .then(res => {
            this.loading2 = false
            if (res.data.code !== 200) {
              Utils.alert({ content: res.data.msg })
              return
            }
            this.loading2 = false
            const ResList = res.data.data.list
            if (ResList.length === 20) {
              this.list = this.list.concat(ResList)
              this.page = this.page + 1
            } else if (ResList.length !== 0) {
              this.list = this.list.concat(ResList)
              this.page = -1
            } else {
              this.page = -1
            }
          })
      } else {
        const data = { xing: this.search_info.xing, page: this.page, search_info: this.search_info }
        axios.post('/name_list_jing/', data)
          .then(res => {
            this.loading2 = false
            if (res.data.code !== 200) {
              Utils.alert({ content: res.data.msg })
              return
            }
            const ResList = res.data.data.list
            if (ResList.length === 20) {
              this.list = this.list.concat(ResList)
              this.page = this.page + 1
            } else if (ResList.length !== 0) {
              this.list = this.list.concat(ResList)
              this.page = -1
            } else {
              this.page = -1
            }
          })
      }
    },
    xml_go_to (e) {
      Utils.go_to(e)
    },
    xml_go_to_only (e) {
      Utils.go_to(e)
    }
  }
}
</script>

<style scoped>
.one_name2{
  margin: 0.40rem 0rem;
  padding: 0.40rem 0.60rem;
  display: block;
  background-color: #fefefe;
  text-align: left;
}

.one_name2 div{
  display: inline-block;
  vertical-align: middle;
}

.one_name2 .name{
  text-align: center;
  height: 1rem;
  width: 2.5rem;
  font-size: 0.60rem;
  line-height: 1rem;
  margin-right: 0.16rem;

}

.one_name2 .desc p{
  display: block;
  color: #212b36;
  font-size: 0.36rem;
  max-width: 5rem;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.head .title p{
  font-size: 0.8rem;
  line-height: 0.8rem;
}
</style>
